import React from 'react';
import {Col, Row} from "antd";

function YarnEstimator() {
    return (
        <>
            <Row>
                <Col span={24}>
                    <h1>How much yarn do I have left?!</h1>
                    <p>Weigh your yarn, enter information about it and presto! Learn the remaining yardage in your skein.</p>
                    <p>I'd love to hear your thoughts about this tool! Did you find a bug? Think of an awesome improvement?
                        You can email me at katharineknits[at]gmail.com</p>
                </Col>
            </Row>
        </>
    )
}

export default YarnEstimator;