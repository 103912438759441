import React from 'react';
import Icon, {CopyrightOutlined, InstagramFilled, MailFilled, YoutubeFilled} from "@ant-design/icons";
import {ReactComponent as RavelrySvg} from "../../assets/ravelry-black.svg";
import {Button, Col, Row, Space, Typography} from "antd";

const {Text} = Typography;

const RavelryIcon = (props: any) => <Icon component={RavelrySvg} {...props} />;

function FooterContents() {
    return (
        <Row>
            <Col xs={24} md={8}><CopyrightOutlined />2022 Katharine Kerr</Col>
            <Col xs={24} md={8} style={{textAlign: "center"}}>
                <Text>Thanks for stopping by! Happy Knitting!</Text>
            </Col>
            <Col xs={24} md={8}>
                <Space style={{float: "right"}}>
                    <Button shape="circle" icon={<InstagramFilled />} href="https://www.instagram.com/katharineknits/" target="_blank"/>
                    <Button shape="circle" icon={<YoutubeFilled />} href="https://www.youtube.com/channel/UCbNuHA8ecnh40l7lIcxUhww" target="_blank"/>
                    <Button shape="circle" icon={<RavelryIcon />} href="https://www.ravelry.com/people/katknitstoo" target="_blank"/>
                    <Button shape="circle" icon={<MailFilled />} href="mailto:katharineknits@gmail.com" target="_blank"/>
                </Space>
            </Col>
        </Row>)
}

export default FooterContents;