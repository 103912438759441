import React from 'react';

function NotFound() {
    return (
        <>
            <p>Page Not Found!</p>
        </>
    )
}

export default NotFound;