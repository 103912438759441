import React from 'react';
import {Button, Col, Image, Row, Space, Typography} from "antd";

import picture from '../assets/self.jpeg';

const {Title, Text} = Typography;

function Newsletter() {
    return (
        <div className="main-content">
            <Row>
                <Col span={24}>
                    <Title level={2}>Newsletter</Title>
                </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={12}>
                    <Image preview={false} src={picture}/>
                </Col>
                <Col xs={24} sm={12}>
                    <Space direction="vertical">
                        <Text>Thank you for your interest in my Newsletter!</Text>
                        <Text>I typically send out one to two emails a month with details on what is new with katknits.
                            This can include new pattern releases, discount codes, events and anything I think is worth
                            sharing.
                        </Text>
                        <Button type="primary" href="http://eepurl.com/hZkzJX" target="_blank">Sign up!</Button>
                    </Space>
                </Col>
            </Row>
        </div>
    )
}

export default Newsletter;