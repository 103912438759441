import React from 'react';
import {
    Outlet,
    useLocation
} from "react-router-dom";

import HeaderContents from "./HeaderContent";
import FooterContents from "./FooterContent";

import {Alert, Button, Layout} from 'antd';
const { Header, Content, Footer } = Layout;

function LayoutContent() {
    const location = useLocation();
    return (
        <Layout className="layout">
            <Alert
                message="For the latest news and discount codes, sign up for the katknits newsletter!"
                banner
                closable
                showIcon={false}
                type="info"
                action={
                    <Button size="small" type="ghost" href="http://eepurl.com/hZkzJX" target="_blank">
                        Sign Up!
                    </Button>
                }
            />
            <Header>
                <HeaderContents
                    selectedMenu={location.pathname}
                ></HeaderContents>
            </Header>
            <Content className="main-content">
                <Outlet />
            </Content>
            <Footer className="footer">
                <FooterContents></FooterContents>
            </Footer>
        </Layout>
    );
}

export default LayoutContent;
