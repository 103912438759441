import React from 'react';
import {Card, Col, Image, Row, Space, Typography} from "antd";
import selfPicture from "../assets/self2.png";
import podcastPicture from "../assets/podcast.png";
import patternPicture from "../assets/Patterns/PacificCrestHat/PCTHat1.jpg";
import pinkLogo from "../assets/PinkLogo.png";
import tools from "../assets/toolsScreenshot.png";
import {Link} from "react-router-dom";

const {Text, Title} = Typography;
const { Meta } = Card;

function Home() {
    return (
        <>
            <Row className="about-me">
                <Col xs={24} md={12}>
                    <Space direction="vertical" align="center" className="main-content">
                        <Title level={1} style={{color: "white"}}><span className="fun-font">About katknits</span></Title>
                        <Text style={{color: "white"}}>
                            I reignited my love of knitting in 2020 and now can hardly go a day without knitting a couple of stitches.
                            I love learning about construction techniques, stitch combinations and fit.
                        </Text>
                        <Text style={{color: "white"}}>
                            I started katknits as a way to share what I've been learning via my YouTube channel and have expanded to
                            building small knitting software tools and creating my own patterns.
                            I'd love to hear from you so please don't hesitate to contact me at katharineknits[at]gmail.com.
                        </Text>
                    </Space>
                </Col>
                <Col xs={24} md={12}>
                    <Image preview={false} src={selfPicture}/>
                </Col>
            </Row>
            <Row className="patterns-preview">
                <Col xs={24} md={8} style={{padding: "8px"}}>
                    <Link to="/patterns">
                        <Card
                            hoverable
                            cover={<img alt="Hat Picture" src={patternPicture} />}
                        >
                            <Meta title="PCT Hat Pattern" />
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} md={8} style={{padding: "8px"}}>
                    <Link to="/tools/weight-estimate">
                        <Card
                            hoverable
                            cover={<img alt="Screenshot of the tools page" src={tools} />}
                        >
                            <Meta title="Tools" />
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} md={8} style={{padding: "8px"}}>
                    <a href="http://eepurl.com/hZkzJX" target="_blank">
                        <Card
                            hoverable
                            cover={<img alt="Katknits logo" src={pinkLogo} />}
                        >
                            <Meta title="Newsletter" />
                        </Card>
                    </a>
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={12}>
                    <Image preview={false} src={podcastPicture}/>
                </Col>
                <Col xs={24} md={12}>
                    <Space direction="vertical" align="center" className="main-content">
                        <Title level={1}><span className="fun-font">katknits is on YouTube!</span></Title>
                        <Text>
                            I have a <a href="https://www.youtube.com/channel/UCbNuHA8ecnh40l7lIcxUhww">monthly knitting podcast</a> where I chat about what I am knitting, what I am excited about
                            and anything I've learnt. I also make short VLOG style videos about life as a knitter and tip videos
                            about the Baby Surprise Jacket by Elizabeth Zimmermann.
                        </Text>
                    </Space>
                </Col>
            </Row>
        </>
    )
}

export default Home;