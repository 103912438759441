import React from 'react';
import { Col, Divider, Image, Row, Space, Typography } from 'antd';

import picture1 from '../assets/Patterns/PacificCrestHat/PCTHat1.jpg';
import picture2 from '../assets/Patterns/PacificCrestHat/PCTHat2.jpg';
import picture3 from '../assets/Patterns/PacificCrestHat/PCTHat3.jpg';

const { Link, Text, Title } = Typography;

function Patterns() {
    return (
        <>
            <Row>
                <Col span={24}>
                    <Title level={2}>Patterns</Title>
                </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={12}>
                    <Space direction="vertical">
                        <Title level={3}>Pacific Crest Hat</Title>
                        <Text strong>As of May 1st 2023, this pattern has been released!</Text>
                        <Text strong>To download the pattern, visit <Link href="https://www.knittingstudy.com">www.knittingstudy.com</Link>.
                            I am running a study on knitting for my Masters of Science - it would be wonderful if you could check it out! It is
                            located at the same link.
                        </Text>

                        <Text>I love the texture and playfulness that comes from holding multiple yarns together and I love
                            finding projects for my leftover yarn. My two loves were combined to create this hat.</Text>
                        {/*<Text>Once released, the pattern for this hat can be purchased on <Link href="https://www.ravelry.com/" target="_blank">Ravelry</Link>*.</Text>*/}
                        {/*<Text italic>*The purchased item is a PDF file (a digital download) that includes written instructions on how to knit the hat yourself.</Text>*/}
                        <Divider orientation="left">Sizes</Divider>
                        <Text>baby (adult small, adult medium, adult large)</Text>
                        <Text>Designed to fit a head with a circumference of
                            14-16 (19-21, 21-23, 22-24) inches / 35.5-41 (48-53.5, 53.5-58.5, 56-61) cm.
                        </Text>
                        <Text type="secondary">Sample was knit in an Adult Medium</Text>
                        <Divider orientation="left">Suggested Needle Size</Divider>
                        <Text>If using magic loop: <br /> US #7 / 4.5mm 16” (or longer) circular needles</Text>
                        <Text>
                            If using DPNs:
                            <br />US #7 / 4.5mm 16” circular needles
                            <br />US #7 / 4.5mm DPNs
                        </Text>
                        <Divider orientation="left">Yarn Overview</Divider>
                        <Text>
                            Multiple strands of yarn are held together to form an aran weight yarn. Yarns are
                            exchanged throughout the hat.
                        </Text>
                        <Text>For this hat, you will hold one strand of fingering and one strand of DK, while changing the DK
                            yarn every other row. Three yarns total: one fingering and two DK yarns.
                        </Text>
                        <Divider orientation="left">Yarn Yardage Estimates</Divider>
                        <Text>
                            The samples were knit with yarns weighing approximately:
                            <br/>Fingering yarn: 400m/ 440 yards/ 100g.
                            <br/>DK yarn: 265m/ 290 yards/ 100g.
                        </Text>
                        <Text>
                            Main Colour (MC) Fingering: 90 (170, 185, 210) yards / 85 (155, 170, 190) meters
                            <br/>Contrast Colour 1 (CC1) DK: 40 (75, 80, 95) yards / 40 (70, 75, 85) meters
                            <br/>Contrast Colour 2 (CC2) DK: 40 (50, 80, 95) yards / 40 (70, 75, 85) meters
                        </Text>
                        <Divider orientation="left">Notions</Divider>
                        <ul>
                            <li>1 Beginning of Round marker (BOR)</li>
                            <li>6 additional stitch markers </li>
                            <li>Darning needle</li>
                            <li>Scissors</li>
                            <li>Measuring Tape</li>
                        </ul>
                        <Divider orientation="left">Skill Level</Divider>
                        <Text>Beginner</Text>
                        <Divider orientation="left">Techniques</Divider>
                        <Text>Long tail cast on, knitting in the round, k2tog decrease.</Text>
                    </Space>
                </Col>
                <Col xs={24} sm={12}>
                    <Image src={picture2}/>
                    <Image src={picture1}/>
                    <Image src={picture3}/>
                </Col>
            </Row>
        </>
    )
}

export default Patterns;