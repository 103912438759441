import './App.less';
import React from 'react';
import {
    Routes,
    Route
} from "react-router-dom";

import Resources from "./components/Resources";
import Patterns from "./components/Patterns";
import Tools from "./components/Tools";
import Newsletter from "./components/Newsletter";
import LayoutContent from "./components/layout/LayoutContent";
import Home from "./components/Home";
import YarnEstimator from "./components/tools/YarnEstimator";
import WeightEstimator from "./components/tools/WeightEstimator";
import NotFound from "./components/NotFound";


function App() {
    return (
        <Routes>
            <Route path="/" element={<LayoutContent />}>
                <Route path="/" element={<Home />} />
                <Route path="resources" element={<Resources />} />
                <Route path="tools/*" element={<Tools />} />
                <Route path="tools/yarn-estimate" element={<YarnEstimator />}></Route>
                <Route path="tools/weight-estimate" element={<WeightEstimator />}></Route>
                <Route path="patterns" element={<Patterns />} />
                <Route path="newsletter" element={<Newsletter />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

export default App;
