import React from 'react';
import {Menu} from "antd";
import {Link} from "react-router-dom";

import type { MenuProps } from 'antd';

function HeaderContents(props: {selectedMenu: string}) {
    const items: MenuProps["items"] = [
        {
            label: <Link to="/resources">Resources</Link>,
            key: "/resources",
        },
        {
            label: <Link to="/patterns">Patterns</Link>,
            key: "/patterns",
        },
        {
            label: "Tools",
            key: "Tools",
            children: [
                {
                    label: <Link to="/tools/weight-estimate">Weight Estimator for Multiple Yarns</Link>,
                    key: "/tools/weight-estimate"
                }
            ],
        },
        {
            label: <Link to="/newsletter">Newsletter</Link>,
            key: '/newsletter',
        },
    ];

    return (<>
        <Link to="/"><h2 className="logo">katknits</h2></Link>
        <Menu selectedKeys={[props.selectedMenu]} mode="horizontal" items={items} className="header-menu"/>
    </>);
}

export default HeaderContents;