import React, {useState} from 'react';
import {Button, Col, Form, Input, Row, Select, Space} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {YARN_WEIGHT} from "../../types/YarnWeights";

const { Option } = Select;

interface IStandardYarn {
    length: number
}

interface IState {
    name: string
}

function WeightEstimator() {
    const [yarn, setYarn] = useState<IState>({ name: "worsted" });
    const [form] = Form.useForm();

    const onValuesChange = function(changedValues: any, values: any) {
        const yarns: IStandardYarn[] = [];
        values.yarns_held.forEach((value: any) => {
            const matchingYarn = YARN_WEIGHT.filter(yarn => yarn.name === value.name);

            yarns.push({
                length: matchingYarn[0].avgMeters
            });
        });

        const newWeight = calculateCombinedWeight(yarns);
        setYarn(newWeight);
    }

    const calculateCombinedWeight = function(yarns: IStandardYarn[]): IState {
        const maxLength = yarns.reduce(function(prev, current) {
            return (prev.length > current.length) ? prev : current
        }).length;

        let totalWeight = 0;
        yarns.forEach(yarn => {
            totalWeight += (maxLength / yarn.length);
        });

        const lengthPer100 = maxLength / totalWeight;

        for (let i = 0; i < YARN_WEIGHT.length; i++) {
            if (lengthPer100 >= YARN_WEIGHT[i].minMeters) {
                return {
                    name: YARN_WEIGHT[i].name
                };
            }
        }

        throw new Error("uh oh - I can't find a yarn that fits!");
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <h1>What is the weight when holding multiple yarns together?</h1>
                    <p>Yarn weight is complicated! The weight of a yarn is determined by the thickness of the yarn, not its physical weight.
                        There are rough guidelines for categorizing a yarn based on the yardage per 100 grams, but that is all estimates.
                        This tool uses the physical weight of yarn, which will give a rough guideline for the thickness of the yarn. </p>
                    <p>I'd love to hear your thoughts about this tool! Did you find a bug? Think of an awesome improvement?
                        You can email me at katharineknits[at]gmail.com</p>
                </Col>
                <Col xs={24} sm={12}>
                    <h3>Yarns Held</h3>
                    <Form
                        name="yarns_held_form"
                        form={form}
                        autoComplete="off"
                        onValuesChange={onValuesChange}
                    >
                        <Form.List
                            name="yarns_held"
                            initialValue={[
                                { name: "fingering" },
                                { name: "fingering" }
                            ]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({key, name, ...restFields}, index) => (
                                        <Space
                                            key={key}
                                            style={{
                                                display: 'flex',
                                                marginBottom: 8,
                                            }}
                                            align="baseline">
                                            <Form.Item
                                                {...restFields}
                                                validateTrigger={['onChange', 'onBlur']}
                                                style={{ width: 200 }}
                                                name={[name, "name"]}
                                            >
                                                <Select placeholder="yarn weight">
                                                    {YARN_WEIGHT.map((yarnWeight) => (
                                                        <Option key= {yarnWeight.name} value={yarnWeight.name}>{yarnWeight.name}</Option>
                                                    ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                            {fields.length > 2 ? (
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(name)}
                                                />
                                            ) : null}
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add({ yarn: "fingering" })}
                                            icon={<PlusOutlined />}
                                        >
                                            Add yarn
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form>
                </Col>
                <Col xs={24} sm={12}>
                    <h3>Estimated Weight</h3>
                    <h2>{yarn.name}</h2>
                </Col>
            </Row>
        </>
    )
}

export default WeightEstimator;