import React from 'react';
import {Card, Col, Row, Typography} from "antd";

const {Text, Title, Link} = Typography

function Resources() {
    return (
        <>
            <Row>
                <Col span={24}>
                    <Title level={2}>Resources</Title>
                    <p>These are some of my favourite resources that I use time and time again!</p>
                </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} md={8}>
                    <Card title="Socks" bordered={false}>
                        <ul>
                            <li>
                                <Link href="https://myjewelthief.typepad.com/myjewelthief_knits/2009/11/shoe-size-charts-for-knitting-socks.html" target="_blank">Sock Sizes</Link>
                                <br/>
                                <Text>[myjewelthief] A very handy guide on sock length based on shoe size</Text>
                            </li>
                        </ul>
                    </Card>
                </Col>
                <Col xs={24} md={8}>
                    <Card title="Sweaters" bordered={false}>
                        <ul>
                            <li>
                                <Link href="https://www.youtube.com/watch?v=8K-IsMdTHK0&t=24s" target="_blank">Avoiding holes in underarm</Link>
                                <br/>
                                <Text>[Suzanne Bryan]</Text>
                            </li>
                        </ul>
                    </Card>
                </Col>
                <Col xs={24} md={8}>
                    <Card title="Cast On + Bind Off" bordered={false}>
                        <ul>
                            <li>
                                <Link href="https://www.youtube.com/watch?v=SBrGhv1_RBU" target="_blank">Tubular Bind Off in the Round</Link>
                                <br/>
                                <Text>[Suzanne Bryan]</Text>
                            </li>
                        </ul>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Resources;