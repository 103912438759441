export interface IYarnWeight {
    name: string,
    weight: number,
    minMeters: number,
    maxMeters: number,
    avgMeters: number
}

export const YARN_WEIGHT: IYarnWeight[] = [
    { name: "lace", weight: 0, minMeters: 600, maxMeters: 1200, avgMeters: 800 },
    { name: "light fingering", weight: 0, minMeters: 500, maxMeters: 599, avgMeters: 500 },
    { name: "fingering", weight: 1, minMeters: 400, maxMeters: 499, avgMeters: 400 },
    { name: "sport", weight: 2, minMeters: 300, maxMeters: 399, avgMeters: 300 },
    { name: "dk", weight: 3, minMeters: 211, maxMeters: 299, avgMeters: 230 },
    { name: "worsted", weight: 4, minMeters: 150, maxMeters: 210, avgMeters: 160 },
    { name: "aran", weight: 4, minMeters: 120, maxMeters: 149, avgMeters: 120 },
    { name: "bulky", weight: 5, minMeters: 99, maxMeters: 119, avgMeters: 100 },    //good range
    { name: "super bulky", weight: 6, minMeters: 40, maxMeters: 99, avgMeters: 40 },
    { name: "jumbo", weight: 7, minMeters: 0, maxMeters: 39, avgMeters: 30 },
];